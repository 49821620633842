import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import "./src/sass/app.scss";

import { navigate } from "gatsby";

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/") {
    navigate("https://www.trajancapital.eu");
  }

  if (location.pathname === "/qui-sommes-nous") {
    navigate("https://www.trajancapital.eu/qui-sommes-nous");
  }

  if (location.pathname === "/nos-entrepreneurs") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs");
  }

  if (location.pathname === "/nos-entrepreneurs/nicolas-paul") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/nicolas-paul");
  }

  if (location.pathname === "/nos-entrepreneurs/brian-boulanger") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/brian-boulanger");
  }

  if (location.pathname === "/nos-entrepreneurs/maxime-rousseau") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/maxime-rousseau");
  }

  if (location.pathname === "/nos-entrepreneurs/pierre-seznec") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/pierre-seznec");
  }

  if (location.pathname === "/nos-entrepreneurs/deborah-berger") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/deborah-berger");
  }

  if (location.pathname === "/nos-entrepreneurs/kevin-ha-julie-joaris") {
    navigate(
      "https://www.trajancapital.eu/nos-entrepreneurs/kevin-ha-julie-joaris"
    );
  }

  if (location.pathname === "/nos-entrepreneurs/samuel-desprets") {
    navigate("https://www.trajancapital.eu/nos-entrepreneurs/samuel-desprets");
  }

  if (location.pathname === "/equipe") {
    navigate("https://www.trajancapital.eu/equipe");
  }

  if (location.pathname === "/responsabilite") {
    navigate("https://www.trajancapital.eu/responsabilite");
  }

  if (location.pathname === "/journal") {
    navigate("https://www.trajancapital.eu/journal");
  }

  if (location.pathname === "/contact") {
    navigate("https://www.trajancapital.eu/contact");
  }
};
